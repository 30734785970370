import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import CardMedia from '@mui/material/CardMedia';
import ImageList from '@mui/material/ImageList';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';

import Container from 'components/Container';


const Annualprize2024 = () => {

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annualprize/1.webp`;
    const p2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annualprize/2.webp`;
    const p3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annualprize/3.webp`;
    const p4 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annualprize/4.webp`;
    const p5 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annualprize/5.webp`;
    const p6 = `${config.base_image_url}/home/home-static/static-images/all-static-images/2024-2025/Annualprize/6.webp`;


    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    {/* <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            ACHIEVING MILESTONES
          </Typography> */}
                    <Typography fontWeight={700} variant={'h4'} align={'center'}>
                        ANNUAL PRIZE DISTRIBUTION CEREMONY
                    </Typography>
                    <br />
                    <Typography
                        fontSize="x-small"
                    ><br /><br />
                        Classes:- 9 to 12        Date: 31 January 2025
                    </Typography>
                    <Typography variant={'subtitle1'} align={'justify'}>
                        National Public School, Yeshwanthpur hosted its Annual Prize Distribution ceremony for the students
                        of classes 9 to 12 on 31 January 2025.
                        <br />
                        The event applauded the saga of outstanding achievements in CBSE clusters, Inter -House sports,
                        Association activities and extracurricular achievements for the academic session 2024-25. Astarea
                        House was conferred the House Trophy, for Discipline, CCA and Association activities. The Sports
                        Trophy was bagged by Aether House and class 11 B stood proud with the Overall Class Discipline
                        Trophy.
                        <br />
                        The prize distribution ceremony boosted the confidence in students. The Principal congratulated all
                        the students to achieve greater heights in the coming years. The event came to a close with a Vote of
                        Thanks proposed by a student of class 10 and 12
                        <br></br>
                        <div>
                        </div>
                    </Typography>
                </Box>
                <Typography
                    variant={'h6'}
                    color={'primary'}
                    align={'center'}
                    fontWeight={'normal'}
                ><br />
                    There are no secrets to success. It is the result of preparation, hard work and learning from failure.
                    <br />
                    <br />
                </Typography>
                {/* <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Card
                sx={{
                  boxShadow: 0,
                  background: 'transparent',
                  backgroundImage: 'none',
                }}
              >
                <Box
                  component={CardMedia}
                  borderRadius={2}
                  width={1}
                  height={1}
                  minHeight={320}
                  image={item.avatar}
                />
                <Box
                  component={CardContent}
                  bgcolor={'transparent'}
                  marginTop={-5}
                >
                  <Box component={Card}>
                    <CardContent>
                      <ListItemText
                        primary={item.name}
                        secondary={item.title}
                      />
                    </CardContent>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid> */}
                <Box>
                    <ImageList
                        variant="quilted"
                        cols={2}
                        rowHeight={isMd ? 300 : 200}
                        gap={isMd ? 16 : 4}
                    >
                        {photos.map((item, i) => (
                            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                <LazyLoadImage
                                    height={'100%'}
                                    width={'100%'}
                                    src={item.src}
                                    alt="..."
                                    effect="blur"
                                    onClick={() => openLightbox(i)}
                                    style={{
                                        objectFit: 'cover',
                                        filter:
                                            theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                        cursor: 'poiner',
                                        borderRadius: 8,
                                    }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                {viewerIsOpen && (
                    <Lightbox
                        mainSrc={photos[currentImage].src}
                        nextSrc={photos[(currentImage + 1) % photos.length].src}
                        prevSrc={
                            photos[(currentImage + photos.length - 1) % photos.length].src
                        }
                        onCloseRequest={() => closeLightbox()}
                        onMovePrevRequest={() =>
                            setCurrentImage((currentImage + photos.length - 1) % photos.length)
                        }
                        onMoveNextRequest={() =>
                            setCurrentImage((currentImage + 1) % photos.length)
                        }
                        reactModalStyle={{ overlay: { zIndex: 1500 } }}
                    />
                )}
                {/* </Box> */}
            </Box>
        </Container>
    );
};

export default Annualprize2024;